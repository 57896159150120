import styled from 'styled-components';
import {
  NewDice1,
  NewDice2,
  NewDice3,
  NewDice4,
  NewDice5,
  NewDice6,
} from "assets/images/dices";

const offset = 25;

export const DiceContainer = styled.div`
  position: relative;
`;

export const MyDicesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  z-index: 1;
  position: relative;
  #dice1,
  #dice2 {
    width: 70px;
    height: 70px;
    transform-style: preserve-3d;
    transition: transform 5s ease-out;
    position: sticky;
    z-index: -1;
    &.paused {
      animation-play-state: paused;
    }

    &.side-1-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-name: rollDiceQuick, side1End !important;
      animation-fill-mode: forwards;
    }

    &.side-2-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-name: rollDiceQuick, side2End !important;
      animation-fill-mode: forwards;
    }

    &.side-3-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-name: rollDiceQuick, side3End !important;
      animation-fill-mode: forwards;
    }

    &.side-4-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-name: rollDiceQuick, side4End !important;
      animation-fill-mode: forwards;
    }

    &.side-5-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-name: rollDiceQuick, side5End !important;
      animation-fill-mode: forwards;
    }

    &.side-6-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-name: rollDiceQuick, side6End !important;
      animation-fill-mode: forwards;
    }

    &.indefinite {
      animation-duration: 2s;
      animation-name: rollDice2;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-timing-function: linear;
    }

    cursor: pointer;
    .sides {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      outline: none;
      box-shadow: inset -0.35rem 0.35rem 0.75rem rgba(0, 0, 0, 0.3),
        inset 0.5rem -0.25rem 0.5rem rgba(0, 0, 0, 0.15);
      border-radius: 20px;
      /* border: solid 1px white; */
      &.side-1 {
        transform: translateZ(${offset}px);
        background: url(${NewDice1});
        background-size: 100% 100%;
      }
      &.side-2 {
        transform: rotateX(-180deg) translateZ(${offset}px);
        background: url(${NewDice2});
        background-size: 100% 100%;
      }
      &.side-3 {
        transform: rotateY(90deg) translateZ(${offset}px);
        background: url(${NewDice3});
        background-size: 100% 100%;
      }
      &.side-4 {
        transform: rotateY(-90deg) translateZ(${offset}px);
        background: url(${NewDice4});
        background-size: 100% 100%;
      }
      &.side-5 {
        transform: rotateX(90deg) translateZ(${offset}px);
        background: url(${NewDice5});
        background-size: 100% 100%;
      }
      &.side-6 {
        transform: rotateX(-90deg) translateZ(${offset}px);
        background: url(${NewDice6});
        background-size: 100% 100%;
      }
    }
  }

  #dice2 {
    &.indefinite {
      animation-duration: 2s;
      animation-name: rollDice;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }

    &.side-1-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side1End !important;
    }

    &.side-2-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side2End !important;
    }

    &.side-3-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side3End !important;
    }

    &.side-4-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side4End !important;
    }

    &.side-5-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side5End !important;
    }

    &.side-6-end {
      animation-duration: .20s !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: forwards;
      animation-name: rollDiceQuick, side6End !important;
    }
  }

  @keyframes rollDice {
    from {
      transform: rotateX(450deg) rotateY(360deg) rotateZ(720deg);
    }
    to {
      transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @keyframes rollDice2 {
    from {
      transform: rotateX(360deg) rotateY(450deg) rotateZ(720deg);
    }
    to {
      transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    }
  }

  @keyframes rollDiceQuick {
    from {
      transform: rotateX(60deg) rotateY(45deg) rotateZ(20deg);
    }
    to {
      transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    }
  }


  @keyframes side1End {
    from {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @keyframes side2End {
    from {
      transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
    }
    to {
      transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
    }
  }

  @keyframes side3End {
    from {
      transform: rotateX(0deg) rotateY(270deg) rotateZ(0deg);
    }
    to {
      transform: rotateX(0deg) rotateY(270deg) rotateZ(0deg);
    }
  }

  @keyframes side4End {
    from {
      transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    }
    to {
      transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
    }
  }

  @keyframes side5End {
    from {
      transform: rotateX(270deg) rotateY(0deg) rotateZ(0deg);
    }
    to {
      transform: rotateX(270deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @keyframes side6End {
    from {
      transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg);
    }
    to {
      transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg);
    }
  }

  @keyframes fadeUp {
    from {
      opacity: 0;
      bottom: 0;
    }

    to {
      opacity: 1;
      bottom: 20px;
    }
  }
`;